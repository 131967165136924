import React from 'react';
import './Resume.css';

const Resume = () => {
    return (
        <div className="resume">
            <header className="resume-header">
                <h1>Pavlos Constas-Malevanets</h1>
                <p>First Year Undergraduate Student in Engineering Science at the University of Toronto</p>
                <p>
                    <a href="tel:226-236-9908">226-236-9908</a> | 
                    <a href="mailto:p.constas@mail.utoronto.ca">p.constas@mail.utoronto.ca</a> | 
                    <a href="http://pavlosconstas.com" target="_blank" rel="noopener noreferrer">pavlosconstas.com</a>
                </p>
            </header>

            <section className="resume-section">
                <h2>Education</h2>
                <div>
                    <strong>BASc in Engineering Science</strong>, University of Toronto, Toronto, CA <br />
                    <span>September 2024 -</span>
                    <ul>
                        <li>3.9 GPA</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section">
                <h2>Research</h2>
                <div>
                    <strong>Toronto Computational Imaging Group</strong>, University of Toronto, Toronto, CA <br />
                    <span>September 2024 -</span>
                </div>
                <div>
                    <strong>Reinforcement Learning Summer Research Group</strong>, University of Toronto, Toronto, CA <br />
                    <span>July 2023 - December 2023</span>
                    <ul>
                        <li>Worked under Professor Michael Guerzhoy on "Toward A Reinforcement-Learning-Based System for Adjusting Medication to Minimize Speech Disfluency".</li>
                        <li>Led the reinforcement learning team and co-wrote the reinforcement learning environment and patient simulation.</li>
                        <li>Assisted in data analysis and contributed to paper writing.</li>
                        <li>Accepted to the Machine Learning for Cognitive and Mental Health (ML4CMH) workshop at AAAI-24.</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section">
                <h2>Work Experience</h2>
                <div>
                    <strong>Assistant Analyst, Research and Developer, Consultant</strong>, Advanced Mineral Technological Laboratory, London, Ontario, CA <br />
                    <span>September 2022 - June 2024</span>
                    <ul>
                        <li>Created software for mineral analysis using CNN, data processing, and computer vision algorithms.</li>
                        <li>Designed and built a peristaltic pump robot for sample polishing.</li>
                        <li>Gained laboratory experience using LECO Carbon-Sulfur assays, electron microscopy, and mass spectrometry.</li>
                        <li>Fixed a longstanding ToF-SIMS mass spectrometer system failure and implemented backup infrastructure.</li>
                        <li>Devised a method to calibrate the color profiles of motorized microscopes to improve uniformity and inter-microscope similarity for sample analysis.</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section">
                <h2>Volunteer Experience</h2>
                <div>
                    <strong>Coordinator / Volunteer</strong>, Chess In The Library - London Public Library, London, Ontario, CA <br />
                    <span>October 2022 - June 2024</span>
                    <ul>
                        <li>Organized a city-wide chess tournament for over 100 children, providing many with their first tournament experience.</li>
                        <li>Instructed individuals ranging in age from 4-80 in the game of chess.</li>
                        <li>Collaborated with coordinators and library staff to establish a program across three locations, benefiting over 100 children.</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section">
                <h2>Awards and Distinctions</h2>
                <ul>
                    <li><strong>Online Physics Olympiad (OPhO) 2023 Top 50 (2%, international)</strong> - July, 2023</li>
                    <li><strong>Canadian Geographic Challenge National Champion</strong> - May, 2023</li>
                    <li><strong>Top 3% in Avogadro Chemistry Contest (international)</strong> - May, 2023</li>
                </ul>
            </section>

            <section className="resume-section">
                <h2>Technical Expertise</h2>
                <ul>
                    <li><strong>Programming:</strong> Python, C/C++, Java, Bash, Rust, R, MATLAB</li>
                    <li><strong>Technologies:</strong> Blender, Linux/Unix Administration, nginx, Blockchain, LaTeX, Altium, CAD, Arduino, Raspberry Pi, Git</li>
                    <li><strong>Scientific Tools:</strong> Mass spectrometry, Electron microscopy, Molecular dynamics, Crystallographic software, Tensorflow, Custom ML Environments, Soldering, PCB Design, Microelectronics</li>
                    <li><strong>Languages:</strong> English (native), Greek (native), German (intermediate, B2), French (intermediate, B1-2)</li>
                </ul>
            </section>
        </div>
    );
};

export default Resume;
